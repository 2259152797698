import * as Sentry from '@sentry/browser';
import settings from '@/settings';

if (import.meta.env.MODE === 'production') {
    Sentry.init(settings.sentry);
} else {
    // eslint-disable-next-line no-console
    console.log('Vite Mode:', import.meta.env.MODE);
    // eslint-disable-next-line no-console
    console.log('Not initializing Sentry');
}
