<script lang="ts">
import { defineComponent } from 'vue';
import settings from '@/settings';

interface BaseData {
    csrfToken: string | null;
    errors: unknown;
    isBusy: boolean;
    remember: boolean;
    user_username: string | null;
    password: string | null;
}


export default defineComponent({
    data() {
        return {
            csrfToken: settings.csrfToken,
            errors: null,
            isBusy: false,
            remember: false,
            user_username: null,
            password: null,
        } as BaseData;
    },

    computed: {
        isFormChanged() : boolean {
            return Boolean(this.user_username) && Boolean(this.password);
        },
    },

    created() {
        this.errors = window.truserveSessionErrors;
    },
});
</script>

<template>
    <div class="row mt-5">
        <div class="offset-sm-3 col-sm-6 offset-lg-4 col-lg-4">
            <div class="card panel-primary">
                <div class="card-header">
                    <h1 class="h3">
                        Login
                    </h1>
                </div>
                <div class="card-body">
                    <form method="post" action="/auth/login">
                        <input type="hidden" name="_token" :value="csrfToken">
                        <div class="form-group required">
                            <label for="user_username">Username</label>
                            <input
                                id="user_username"
                                v-model="user_username"
                                class="form-control"
                                name="user_username"
                                required
                            >
                        </div>

                        <div class="form-group required">
                            <label for="password">Password</label>
                            <input
                                id="password"
                                v-model="password"
                                class="form-control"
                                type="password"
                                name="password"
                                required
                            >
                        </div>

                        <div>
                            <input
                                id="remember"
                                v-model="remember"
                                type="checkbox"
                                name="remember"
                                :true-value="true"
                                :false-value="false"
                            >
                            Keep me signed in
                        </div>

                        <button
                            class="btn btn-success btn-sm m-2"
                            type="submit"
                            :disabled="!isFormChanged || isBusy"
                        >
                            <span class="fas fa-share" /> Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
